export default {
  omwServer: {
    url: 'https://cp4k86k4nl.execute-api.eu-west-2.amazonaws.com/ttgb-prod/onmyway?token',
    apiKey: 'cb94d0fe-23e8-4481-ab11-226841255daf',
  },
  epsagon: {
    token: 'ceab8918-ea52-4ce0-9d62-97d14aefe6c4',
    appName: 'ttgb-prod-omw',
  },
  here: {
    apiKey: 'Q977kVgv3BgJSo89bM6KRNDnn2F0lqgl8_2EI1Sdv_s',
    mapHeightMobile: '40vh',
    mapHeightDesktop: '65vh',
    mapWidthMobile: '93vw',
    mapWidthDesktop: '47vw',
    mapWidth: '100%',
    imperial: true,
    showZoom: true,
    majorVersion: 'v3',
    minorVersion: '3.1.30.3',
    centreLat: 53.361463,
    centreLng: -1.67194,
    defaultZoom: 14,
  },
  marketing: {
    enabled: false,
    customer: 'ttgb',
    retrievalUrl: '',
    recordClickUrl: '',
    fieldName: 'category',
    demo: false,
    oldStyle: true,
  },
  feedback: {
    enabled: true,
    url: 'https://4bxxpmtu2h.execute-api.eu-west-2.amazonaws.com/latest/save',
    timeout: 5000,
  },
  intercom: {
    enabled: true,
    appId: 'ze83rsbh',
    onlineDays: {
      1: {
        showTime: '09:00',
        hideTime: '18:00',
      },
      2: {
        showTime: '09:00',
        hideTime: '18:00',
      },
      3: {
        showTime: '09:00',
        hideTime: '18:00',
      },
      4: {
        showTime: '09:00',
        hideTime: '18:00',
      },
      5: {
        showTime: '09:00',
        hideTime: '18:00',
      },
      6: {
        showTime: '09:00',
        hideTime: '16:00',
      },
    },
    // onlineBuckets: ['50015918', '50015919', '50015920']
  },
  trustpilot: {
    enabled: true,
    dataLocale: 'en-GB',
    dataTemplateId: '5419b6ffb0d04a076446a9af',
    dataBusinessUnitId: '4be53f1500006400050920ee',
    dataStyleHeight: '100%',
    dataStyleWidth: '100%',
    dataTheme: 'light',
    url: 'https://uk.trustpilot.com/review/www.worcester-bosch.co.uk',
  },
  logos: [
    {
      filename: 'gas-safe-logo.png',
      label: 'Gas Safe',
      value: '18091',
    },
    {
      filename: 'oftec-logo.png',
      label: 'OFTEC',
      value: 'C688',
    },
  ],
  display: {
    fontFamily:
      'Lato,BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
    dateFormat: 'dd MMM yyyy',
    timeFormat: 'h:mma',
    eta: {
      useCalculatedEta: false,
      bufferBeforeMins: 10,
      bufferAfterMins: 10,
    },
    disableContactUs: true,
    defaultEngineerName: 'Your Bosch engineer',
    defaultEngineerPhoto: 'default-engineer.jpg',
    customerWebsite: 'https://www.worcester-bosch.co.uk',
    notification: {
      show: true,
    },
    licensePlateEnabled: false,
    showLicensePlate: false,
    licencePlateCountry: 'xx',
    engineerIcon: {
      opacity: 0.9,
      width: 50,
      height: 30,
      anchorX: 30,
      anchorY: 30,
      svg: `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="1133.9" height="512" viewBox="0 0 1133.9 566.9">
      <style type="text/css">
    .st0{fill:{secondaryColor};}
  </style>
  <g>
    <path class="st0" d="M1085.9,361.7c-1.7-8.6-6.9-29.4-18.9-49.7c-15.7-26.3-70.6-46.1-70.6-46.1l-79.6-28.1L759.6,125.1l-59.3-42.8
      C621.9,32.1,547,30,547,30H120.8c-18.9,0-24.2,31.3-24.2,31.3S67.4,207.1,60.1,250.7c-7.2,43-7.1,56.8-7.1,56.8l4.9,150.7
      c0,0,0.3,8.5,9.2,8.5h105.7h0.3c7.3,42,43.9,73.9,87.9,73.9c44.5,0,81.5-32.7,88.1-75.4h0.2h441.3c6.3,43,43.4,76,88.2,76
      s81.8-33,88.2-76l113.7-17.7c0,0,11.5-1.9,11.5-16.3L1085.9,361.7z M144.7,225.8c0,0,17.8,56.9,96.8,9.3l0.2-0.2L428.6,92.5
      c0,0,32.2-24.6,64-25.4c0,0,64-8.1,57.9,57.9c0,0-17.8-57.1-96.8-9.5L266.9,258.1l-0.3,0.2c0,0-32.1,24.6-64,25.4
      C202.6,283.7,138.6,291.8,144.7,225.8z M261.2,498.1c-27.4,0-49.7-22.3-49.7-49.7s22.3-49.7,49.7-49.7s49.7,22.3,49.7,49.7
      S288.6,498.1,261.2,498.1z M554.1,193.1c0,0-17.8-57.1-96.8-9.5l-187,142.7l-0.3,0.2c0,0-32.1,24.6-63.9,25.4c0,0-64,8.1-57.9-57.9
      c0,0,17.7,56.9,96.8,9.2l0.3-0.2l186.9-142.4c0,0,32.2-24.6,64-25.4C496.2,135.2,560.2,127.1,554.1,193.1z M668.6,243.7
      c-7.2,0-7.3-7.3-7.3-7.3V117.7c0-6,5.9-6.3,5.9-6.4h49.6l138.4,99.8c0,0,0,21.1,0,26.5s-6.1,6.1-6.1,6.1S675.8,243.7,668.6,243.7z
       M878.4,499.8c-27.4,0-49.7-22.3-49.7-49.7s22.3-49.7,49.7-49.7s49.7,22.3,49.7,49.7S905.8,499.8,878.4,499.8z"/>
  </g>
    </svg>`,
    },
    activityIcon: {
      opacity: 0.4,
      svg: `<svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" width="563.44257" height="792.94273" viewBox="0 0 47 60">
              <title>flag2</title>
              <rect x="2" y="4" width="45" height="32" style="fill: {tertiaryColor}"/>
              <path d="M7.5,0a1,1,0,0,0-1,1V59a1,1,0,0,0,2,0V1A1,1,0,0,0,7.5,0Z" transform="translate(-6.5)" style="fill: #424a60"/>
            </svg>`,
      width: 30,
      height: 50,
      anchorX: 0,
      anchorY: 50,
    },
    contactCentreNo: '0330 123 9339',
    contactUsAddress:
      'https://www.worcester-bosch.co.uk/support/online-support',
    loadingLogo: 'logo.png',
    companyLogo: 'logo.png',
    headerHeight: '4em',
    // imageHeight: '3.5em',
    imageWidth: '15em',
    footerText: `
      <div style="white-space:nowrap">&#xa9 Worcester Bosch {year} - Leadent.digital On My Way</span>
        <span style="white-space:nowrap">
      <br/></div>`,
    showDeliveryForFuture: false,
  },
  reschedule: {
    enabled: true,
    url: 'https://cp4k86k4nl.execute-api.eu-west-2.amazonaws.com/ttgb-prod',
    daysToRetrieve: 30,
    allowStartSameDay: false,
    rebookCutoff: {
      numberOfDaysInFuture: 0,
      cutoffTime: '00:00',
    },
    hideRagRed: false,
    expiryMins: 5,
    allowEarlierThanOriginal: true,
    dateFormat: 'dd MMM yyyy',
    timeFormat: 'h:mma',
    showTimezoneLabel: false,
    allowContactCentreRebook: true,
    rebookBuckets: [],
    enableDatepicker: true,
    blockRebookOnApptDay: true,
  },
};
