<template>
  <div>
    <div
      ref="map"
      class="here-map"
      :style="{ width, height }"
      style="display: inline-block; cursor: grab"
    />

    <div class="buttons mt-2 mb-3" style="justify-content: center">
      <b-button
        type="is-omw-buttons"
        class="has-text-weight-semibold custom-button mr-4"
        @click="recenter"
      >
        {{ $t('map-centre-map-button-label') }}
      </b-button>
      <b-button
        type="is-omw-buttons"
        class="has-text-weight-semibold custom-button ml-4"
        @click="getData()"
      >
        {{ $t('map-update-data-button-label') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { supportsPassiveEvents } from 'detect-passive-events';
import { defineComponent } from '@vue/composition-api';
import styles from '@/sass/variables.scss';

export default defineComponent({
  name: 'HereMap',
  props: {
    apiKey: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
    imperial: {
      type: Boolean,
      default: true,
    },
    showZoom: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      routeColor: styles.route || '#245A9D',
      pixelRatio: 1.0,
      showLegend: false,
      map: {},
      platform: {},
      defaultLayers: {},
      behavior: {},
      markerGroup: undefined,
      router: {},
      ui: {},
      center: undefined,
      routeLine: {},
      engineerMarker: undefined,
      activityMarker: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'apptInFuture',
      'engineerDetails',
      'activityDetails',
      'token',
    ]),
    zoomLevel() {
      return !this.bothMarkersPresent ? 16 : undefined;
    },
    bothMarkersPresent() {
      if (this.activityMarker && this.engineerMarker) {
        return true;
      }
      return false;
    },
    showEngineerMarker() {
      if (this.apptInFuture) return false;
      return (
        this.engineerDetails &&
        this.engineerDetails.latitude &&
        this.engineerDetails.longitude
      );
    },
    showHomeMarker() {
      return (
        this.activityDetails &&
        this.activityDetails.latitude &&
        this.activityDetails.longitude
      );
    },
  },
  watch: {
    center: {
      handler(newVal) {
        this.map
          .getViewModel()
          .setLookAtData({ bounds: newVal, zoom: this.zoomLevel });
      },
    },
    engineerDetails: {
      deep: true,
      immediate: false,
      handler(newVal) {
        if (this.showEngineerMarker) {
          this.setEngineerMarker(newVal);
        }
      },
    },
    activityDetails: {
      deep: true,
      immediate: false,
      handler(newVal) {
        if (this.showHomeMarker) {
          this.setActivityMarker(newVal);
        }
      },
    },
  },
  mounted() {
    this.$nextTick(async () => {
      const api = await this.$heremaps.load();
      const defaultCentreLat = this.$omwConfig.here.centreLat;
      const defaultCentreLng = this.$omwConfig.here.centreLng;
      const defaultZoom = this.$omwConfig.here.defaultZoom;
      this.platform = new api.service.Platform({
        apikey: this.apiKey,
      });
      if (!this.markerGroup) {
        this.markerGroup = new api.map.Group();
      }
      this.pixelRatio = api.devicePixelRatio || 1;
      this.defaultLayers = this.platform.createDefaultLayers({
        tileSize: this.pixelRatio === 1 ? 256 : 512,
        ppi: this.pixelRatio === 1 ? undefined : 320,
      });
      this.defaultLayers.crossOrigin = true;
      this.map = new api.Map(
        this.$refs.map,
        this.defaultLayers.vector.normal.map,
        {
          zoom: defaultZoom,
          center: {
            lat: defaultCentreLat,
            lng: defaultCentreLng,
          },
          pixelRatio: this.pixelRatio,
        },
      );
      window.addEventListener(
        'resize',
        this.handleResize,
        supportsPassiveEvents ? { passive: true } : false,
      );
      this.behavior = new api.mapevents.Behavior(
        new api.mapevents.MapEvents(this.map),
      );
      // Create the default UI components
      this.ui = api.ui.UI.createDefault(this.map, this.defaultLayers);
      this.ui.setUnitSystem(
        this.imperial ? api.ui.UnitSystem.IMPERIAL : api.ui.UnitSystem.METRIC,
      );
      this.handleZoomOption();
      if (!this.markerGroup) {
        this.markerGroup = new api.map.Group();
      }
      this.map.addObject(this.markerGroup);
      this.router = this.platform.getRoutingService(null, 8);
      if (this.showEngineerMarker) {
        this.setEngineerMarker(this.engineerDetails);
      }
      if (this.showHomeMarker) {
        this.setActivityMarker(this.activityDetails);
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapActions(['setOmwData', 'setLoading']),
    async getData() {
      try {
        this.setLoading(true);
        await this.setOmwData(this.token);
      } finally {
        this.setLoading(false);
      }
    },
    handleZoomOption() {
      if (!this.showZoom) {
        const zoom = this.ui.getControl('zoom');
        zoom.setDisabled(true);
        zoom.setVisibility(false);
        this.behavior.disable(window.H.mapevents.Behavior.WHEELZOOM);
        this.behavior.disable(window.H.mapevents.Behavior.PINCH_ZOOM);
        this.behavior.disable(window.H.mapevents.Behavior.DBL_TAP_ZOOM);
        this.behavior.enable(window.H.mapevents.Behavior.DRAGGING);
      }
    },
    handleResize() {
      this.map.getViewPort().resize();
    },
    recenter() {
      this.map.getViewModel().setLookAtData({ bounds: this.center });
    },
    removeExistingEngineerMarker() {
      if (this.markerGroup && this.engineerMarker) {
        this.markerGroup.removeObject(this.engineerMarker);
        this.engineerMarker = undefined;
      }
    },
    removeExistingActivityMarker() {
      if (this.markerGroup && this.activityMarker) {
        this.markerGroup.removeObject(this.activityMarker);
        this.activityMarker = undefined;
      }
    },
    async setActivityMarker(activityDetails) {
      const api = await this.$heremaps.load();
      this.removeExistingActivityMarker();
      if (activityDetails?.longitude && activityDetails?.latitude) {
        const icon = new api.map.Icon(this.activityIcon, {
          size: {
            w: this.activityIconWidth,
            h: this.activityIconHeight,
          },
          anchor: {
            x: this.activityIconAnchorX,
            y: this.activityIconAnchorY,
          },
        });
        this.activityMarker = new api.map.Marker(
          {
            lat: activityDetails.latitude,
            lng: activityDetails.longitude,
          },
          { icon },
        );
        if (!this.markerGroup) {
          this.markerGroup = new api.map.Group();
        }
        this.markerGroup.addObject(this.activityMarker);
        this.center = this.markerGroup.getBoundingBox();
        this.map.getViewModel().setLookAtData({
          bounds: this.center,
          zoom: this.zoomLevel,
        });
      }
    },
    async displayRoute() {
      const api = await this.$heremaps.load();
      const routingParameters = {
        routingMode: 'fast',
        transportMode: 'car',
        // The start point of the route:
        origin: `${this.engineerDetails.latitude},${this.engineerDetails.longitude}`,
        // The end point of the route:
        destination: `${this.activityDetails.latitude},${this.activityDetails.longitude}`,
        return: 'polyline',
      };
      this.router.calculateRoute(
        routingParameters,
        (result) => {
          let lineString;
          if (result.routes.length) {
            const arrivalTime =
              result?.routes?.[0]?.sections?.[0]?.arrival?.time;
            if (arrivalTime) {
              this.$store.dispatch('setCalculatedEta', arrivalTime);
            }
            lineString = api.geo.LineString.fromFlexiblePolyline(
              result.routes[0].sections[0].polyline,
            );
            this.routeLine = new api.map.Polyline(lineString, {
              style: {
                strokeColor: this.routeColor,
                lineWidth: 2,
              },
            });
            this.map.addObject(this.routeLine);
            const bounds = this.routeLine.getBoundingBox();
            const top = bounds.getTop();
            const bottom = bounds.getBottom();
            const left = bounds.getLeft();
            const right = bounds.getRight();
            // Adjust the bounds very slightly to prevent the icons;
            // hanging off the edge;
            const newBounds = {
              newTop: top + 0.00002,
              newLeft: left - 0.0008,
              newBottom: bottom - 0.00002,
              newRight: right + 0.0008,
            };
            this.center = new api.geo.Rect(
              newBounds.newTop,
              newBounds.newLeft,
              newBounds.newBottom,
              newBounds.newRight,
            );
            this.map.getViewModel().setLookAtData({ bounds });
          }
        },
        (error) => {
          console.warn(error);
        },
      );
    },
    async setEngineerMarker(engineerDetails) {
      const api = await this.$heremaps.load();
      this.removeExistingEngineerMarker();
      if (engineerDetails?.latitude && engineerDetails?.longitude) {
        const icon = new api.map.Icon(this.engineerIcon, {
          size: {
            w: this.engineerIconWidth,
            h: this.engineerIconHeight,
          },
          asCanvas: true,
          anchor: {
            x: this.engineerIconAnchorX,
            y: this.engineerIconAnchorY,
          },
        });
        this.engineerMarker = new api.map.Marker(
          {
            lat: engineerDetails.latitude,
            lng: engineerDetails.longitude,
          },
          { icon },
        );
        if (!this.markerGroup) {
          this.markerGroup = new api.map.Group();
        }
        this.markerGroup.addObject(this.engineerMarker);

        if (!this.center) {
          this.center = this.markerGroup.getBoundingBox();
        }
        this.$nextTick(() => {
          if (this.engineerMarker && this.activityMarker) {
            this.displayRoute();
          }
        });
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/sass/variables.scss';

.here-map {
  margin-top: 0 0 0 0;
}
.custom-button {
  width: 8em;
}
</style>
