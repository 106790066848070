<template>
  <div class="container is-fluid">
    <div
      v-if="!sortedSlots || sortedSlots.length === 0"
      class="has-text-centered"
    >
      <div v-if="!loading">
        <p class="has-text-weight-semibold is-size-5">
          {{ $t('rebook-try-other-date') }}
        </p>
        <p>{{ $t('rebook-try-other-date-subheading') }}</p>
      </div>
    </div>
    <div
      v-for="slot in sortedSlots"
      v-else
      :key="slot.slotId"
      class="notification is-rebook-appointment-slot rebookable"
      style="width: 20rem; margin-left: auto; margin-right: auto"
      @click="showSlotSelected(slot)"
    >
      <div class="columns is-mobile">
        <div class="column is-10">
          <p class="has-text-weight-semibold slot-text">
            {{ slotText(slot) }}
          </p>
        </div>

        <div class="column">
          <b-icon
            v-if="showIcon(slot.ragValue)"
            icon="truck"
            :type="getIconColor(slot.ragValue)"
          >
          </b-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import copy from 'fast-copy';

export default defineComponent({
  props: {
    slots: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dateFormat: this.$omwConfig.reschedule.dateFormat,
      timeFormat: this.$omwConfig.reschedule.timeFormat,
    };
  },
  computed: {
    ...mapGetters(['activityTimezone']),
    sortedSlots() {
      const slotsCopy = copy(this.slots);
      return slotsCopy.sort((a, b) => {
        const endDateA = DateTime.fromISO(a.endTime);
        const endDateB = DateTime.fromISO(b.endTime);
        if (endDateA < endDateB) return -1;
        if (endDateA > endDateB) return 1;
        return 0;
      }); // Sort the slots by decending end date so the earlier finishing slots show first in the list
    },
  },
  methods: {
    slotText(slot) {
      const start = DateTime.fromISO(slot.startTime, {
        zone: this.activityTimezone,
      });
      const end = DateTime.fromISO(slot.endTime, {
        zone: this.activityTimezone,
      });
      return `${start.toFormat(this.timeFormat).toLowerCase()} - ${end
        .toFormat(this.timeFormat)
        .toLowerCase()}`;
    },
    showSlotSelected(slot) {
      const start = DateTime.fromISO(slot.startTime, {
        zone: this.activityTimezone,
      });
      const end = DateTime.fromISO(slot.endTime, {
        zone: this.activityTimezone,
      });
      const formattedDate = start.toFormat(this.dateFormat);
      const formattedStartTime = start.toFormat(this.timeFormat).toLowerCase();
      const formattedEndTime = end.toFormat(this.timeFormat).toLowerCase();
      const dateString = `${formattedDate} ${formattedStartTime} - ${formattedEndTime}`;
      this.$buefy.dialog.confirm({
        message: this.$t('rebook-confirm-question', {
          datetime: dateString,
        }),
        type: 'is-rebook-button',
        confirmText: this.$t('rebook-confirm-label'),
        cancelText: this.$t('rebook-return-label'),
        onConfirm: () =>
          this.$emit('accept', {
            slotId: slot.slotId,
            startTime: slot.startTime,
            endTime: slot.endTime,
            apptRequestId: slot.apptRequestId,
          }),
      });
    },
    showIcon(ragStatus) {
      if (ragStatus === 'GREEN') return true;
      return false;
    },
    getIconColor(ragStatus) {
      if (ragStatus === 'GREEN') return 'is-rebook-eco-truck';
      return 'is-danger';
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.rebookable {
  cursor: pointer;
}

.slot-text {
  color: $rebook-appointment-slot-text;
}
</style>
