import axios from 'axios';

import omwConfig from '@/config/config';
import store from '@/store';

export async function getData(token) {
  const contactCentre = store.getters.contactCentre;
  const response = await axios.get(
    `${omwConfig.omwServer.url}=${token}&contactCentre=${String(
      contactCentre,
    )}`,
    {
      headers: {
        'x-api-key': omwConfig.omwServer.apiKey,
      },
      crossdomain: true,
      timeout: 30_000,
    },
  );
  if (response.status === 204) throw new Error('No appointment retrieved');
  return response.data;
}
