import { DateTime } from 'luxon';
import { calculateEtaBuffer } from '@/utils';
import configData from '@/config/config';
import { getData } from '@/services/omwData';

const TIME_FORMAT = configData?.display?.timeFormat || 'hh:mm';

function isInFuture(assignedDate) {
  if (assignedDate) {
    const comparisonDate = DateTime.fromISO(assignedDate).startOf('day');
    const now = DateTime.now().startOf('day');
    const diff = comparisonDate.diff(now, 'days');
    return diff.days >= 1;
  }
  return false;
}
function isInPast(assignedDate) {
  if (assignedDate) {
    const comparisonDate = DateTime.fromISO(assignedDate).startOf('day');
    const now = DateTime.now().startOf('day');
    const diff = comparisonDate.diff(now, 'day');
    return diff.days <= -1;
  }
  return false;
}

function formatTimeSlotValue(fieldName, activityDetails) {
  if (!activityDetails[fieldName]) return;
  let parsed = DateTime.fromISO(activityDetails.date);

  const time = activityDetails[fieldName].split(':');
  parsed = parsed.set({
    hours: time[0],
    minutes: time[1],
  });

  return parsed.toFormat(TIME_FORMAT);
}

export const mutations = {
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  SET_CONTACT_CENTRE(state, payload) {
    state.contactCentre = payload;
  },
  SET_DISMISS_NOTIFICATION(state, payload) {
    state.dismissNotification = payload;
  },
  SET_SHOW_INTERCOM(state, payload) {
    state.shouldShowIntercom = payload;
  },
  SET_LANG(state, payload) {
    this.$i18n.locale = payload;
  },
  SET_LOADING(state, loading) {
    state.isLoading = loading;
  },
  SET_ACTIVITY_DETAILS(state, activityDetails) {
    state.activityDetails = activityDetails;
  },
  SET_DATASET_ID(state, datasetId) {
    state.datasetId = datasetId;
  },
  SET_ENGINEER_DETAILS(state, engineerDetails) {
    state.engineerDetails = engineerDetails;
  },
  SET_CURRENT_DATE(state, currentDate) {
    state.currentDate = currentDate;
  },
  SET_MAP_INIT_COMPLETE(state, initComplete) {
    state.mapInitComplete = initComplete;
  },
  SET_CALCULATED_ETA(state, eta) {
    state.calculatedEta = eta;
  },
  SET_RESCHEDULED_DETAILS(state, payload) {
    state.rescheduleDetails = payload;
  },
  SET_APPT_WINDOW_END(state, payload) {
    state.apptWindowEnd = payload;
  },
};
export const actions = {
  setToken({ commit }, payload) {
    commit('SET_TOKEN', payload);
  },
  setContactCentre({ commit }, payload) {
    commit('SET_CONTACT_CENTRE', payload);
  },
  async setOmwData({ commit, state }) {
    const data = await getData(state.token);
    commit('SET_ACTIVITY_DETAILS', data.activity);
    commit('SET_ENGINEER_DETAILS', data.resource);
    commit('SET_APPT_WINDOW_END', data.apptWindowEnd);
    commit('SET_DATASET_ID', data.datasetId || data.ofscInstance);
    if (!data) throw new Error('No appointment retrieved');
  },
  setDismissNotification({ commit }, payload) {
    commit('SET_DISMISS_NOTIFICATION', payload);
  },
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload);
  },
  setShouldShowIntercom({ commit }, payload) {
    commit('SET_SHOW_INTERCOM', payload);
  },
  setLang({ commit }, payload) {
    commit('SET_LANG', payload);
  },
  async setLangNew({ commit }, payload) {
    if (payload in this.$i18n.messages) {
      commit('SET_LANG', payload);
    } else {
      try {
        const res = await import(/* @vite-ignore */ `@/lang/${payload}.json`);
        this.$i18n.setLocaleMessage(payload, res.data);
        commit('SET_LANG', payload);
      } catch (e) {
        // nothing to do
      }
    }
  },
  setEngineerDetails({ commit }, engineerDetails) {
    commit('SET_ENGINEER_DETAILS', engineerDetails);
  },
  setActivityDetails({ commit }, activityDetails) {
    commit('SET_ACTIVITY_DETAILS', activityDetails);
  },
  setCurrentDate({ commit }, currentDate) {
    commit('SET_CURRENT_DATE', currentDate);
  },
  setMapInitComplete({ commit }, initComplete) {
    commit('SET_MAP_INIT_COMPLETE', initComplete);
  },
  setCalculatedEta({ commit }, eta) {
    commit('SET_CALCULATED_ETA', eta);
  },
  setRescheduleDetails({ commit }, payload) {
    commit('SET_RESCHEDULED_DETAILS', payload);
  },
  setApptWindowEnd({ commit }, payload) {
    commit('SET_APPT_WINDOW_END', payload);
  },
};

export const getters = {
  token: (state) => state.token,
  contactCentre: (state) => state.contactCentre,
  isLoading: (state) => state.isLoading,
  loadingText: (state) => state.loadingText,
  dismissNotification: (state) => state.dismissNotification,
  error: (state) => state.error,
  activityDetails: (state) => state.activityDetails,
  engineerDetails: (state) => state.engineerDetails,
  currentDate: (state) => state.currentDate,
  formattedDate: (state) => {
    if (!state.activityDetails.date) return;

    const dateObj = DateTime.fromISO(state.activityDetails.date);

    return dateObj.toLocaleString({
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  },
  apptInFuture: (state) => isInFuture(state.activityDetails.date),
  apptInPast: (state) => isInPast(state.activityDetails.date),
  shouldShowIntercom: (state) => state.shouldShowIntercom,
  mapInitComplete: (state) => state.mapInitComplete,
  calculatedEta: (state) => {
    if (!state.calculatedEta) return undefined;
    return calculateEtaBuffer(state.calculatedEta);
  },
  rescheduleDetails: (state) => state.rescheduleDetails,
  formattedServiceWindowStart: (state) => {
    if (!state?.activityDetails.date) return undefined;
    return formatTimeSlotValue('trimServiceWindowStart', state.activityDetails);
  },
  formattedServiceWindowEnd: (state) => {
    if (!state?.activityDetails.date) return undefined;
    return formatTimeSlotValue('trimServiceWindowEnd', state.activityDetails);
  },
  formattedDeliveryWindowStart: (state) => {
    if (!state?.activityDetails.date) return undefined;
    return formatTimeSlotValue(
      'trimDeliveryWindowStart',
      state.activityDetails,
    );
  },
  formattedDeliveryWindowEnd: (state) => {
    if (!state?.activityDetails.date) return undefined;
    return formatTimeSlotValue('trimDeliveryWindowEnd', state.activityDetails);
  },
  activityTimezone: (state) => {
    if (!state?.activityDetails?.timezone) return DateTime.now().zoneName;
    return state?.activityDetails?.timezone;
  },
  apptWindowEnd: (state) => state.apptWindowEnd,
  datasetId: (state) => state.datasetId,
};
