export const state = {
  token: undefined,
  contactCentre: false,
  lang: 'en',
  isLoading: true,
  activityDetails: {},
  engineerDetails: {},
  currentDate: new Date(),
  shouldShowIntercom: false,
  dismissNotification: false,
  mapInitComplete: false,
  calculatedEta: undefined,
  rescheduleDetails: undefined,
  apptWindowEnd: undefined,
  datasetId: undefined,
};
