<template>
  <div>
    <div class="confirmation" style="margin-left: auto; margin-right: auto">
      <p
        v-html="
          $t('rebook-confirmation', {
            apptNumber: activityDetails.apptNumber,
            rescheduleDate: appointment,
          })
        "
      ></p>
      <br />
      <p
        v-html="
          $t('rebook-confirm-cancel-contact-us', {
            contactNumber: $omwConfig.display.contactCentreNo,
          })
        "
      ></p>
      <p class="mt-2">{{ $t('rebook-update-delay-guidance') }}</p>
    </div>
    <div class="has-text-centered">
      <post-process-home-button />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent } from '@vue/composition-api';
import { DateTime } from 'luxon';
import PostProcessHomeButton from './PostProcessReturnButton.vue';

export default defineComponent({
  name: 'AppointmentConfirmation',
  components: {
    PostProcessHomeButton,
  },
  data() {
    return {
      dateFormat: this.$omwConfig.reschedule.dateFormat,
      timeFormat: this.$omwConfig.reschedule.timeFormat,
    };
  },
  computed: {
    ...mapGetters(['rescheduleDetails', 'activityDetails', 'activityTimezone']),
    appointment() {
      if (!this.rescheduleDetails) return '';
      const apptStartTime = DateTime.fromISO(this.rescheduleDetails.startTime, {
        zone: this.activityTimezone,
      });
      const apptEndTime = DateTime.fromISO(this.rescheduleDetails.endTime, {
        zone: this.activityTimezone,
      });
      const dateFrom = apptStartTime.toFormat(this.dateFormat);
      const timeFrom = apptStartTime.toFormat(this.timeFormat).toLowerCase();
      const timeTo = apptEndTime.toFormat(this.timeFormat).toLowerCase();
      return `<b>${dateFrom} ${timeFrom} - ${timeTo}</b>`;
    },
  },
});
</script>

<style scoped>
.confirmation {
  border: 1px solid lightgrey;
  border-radius: 10px;
  margin: 1em;
  padding: 1em;
  width: 28rem;
}
</style>
