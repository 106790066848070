<template>
  <div>
    <div v-if="!rescheduleDetails">
      <span class="columns">
        <div v-show="activityDetails" class="column is-half">
          <appointment-details :width="mapWidth" />
          <here-map
            v-if="showMap"
            :height="mapHeight"
            :width="$omwConfig.here.mapWidth"
            :api-key="$omwConfig.here.apiKey"
            :imperial="$omwConfig.here.imperial"
            :show-zoom="$omwConfig.here.showZoom"
          />
        </div>
        <div class="column is-half">
          <engineer-panel
            :photo-source="photoSource"
            :logos="logos"
            :resource-type="resourceType"
            :licence-plate="engineerDetails.licencePlate"
          />
          <div v-if="marketingEnabled && !marketingCampaignFailed" class="pt-4">
            <marketing
              v-show="marketingReady"
              ref="marketing"
              @campaign-failed="marketingFailed"
              @campaign-loaded="nowShowMarketing"
            />
            <b-skeleton
              :height="engineerPanelHeight"
              size="is-large"
              :active="!marketingReady"
            ></b-skeleton>
          </div>
          <additional-info v-if="additionalInfoEnabled" />
          <div style="text-align: center" class="pt-5 faint-box">
            <!-- eslint-disable vue/no-v-html -->
            <p
              v-if="contactUsEnabled"
              class="is-size-5 has-text-weight-semibold"
              v-html="
                $t('main-rearrange-appointment-text', {
                  contactCentreNumber: $omwConfig.display.contactCentreNo,
                })
              "
            ></p>
            <div
              v-if="rescheduleEnabled"
              class="column"
              style="text-align: center; padding: 1em 0em"
            >
              <div class="pb-1">
                <b-button
                  type="is-rebook-button"
                  class="has-text-weight-semibold"
                  @click="showReschedule"
                  >{{ $t('rebook-rebook-button-label') }}</b-button
                >
                <p class="pt-2 has-text-weight-semibold">
                  {{ $t('rebook-button-guidance') }}
                </p>
              </div>
            </div>
            <!-- eslint-enable vue/no-v-html -->
            <div class="column has-text-centered mb-2">
              <span
                v-if="trustpilotEnabled"
                style="
                  display: block;
                  max-height: 40px;
                  padding: 2em 0em 5em 0em;
                "
              >
                <trustpilot
                  :style="trustpilotPadding"
                  :data-locale="$omwConfig.trustpilot.dataLocale"
                  :data-template-id="$omwConfig.trustpilot.dataTemplateId"
                  :data-business-unit-id="
                    $omwConfig.trustpilot.dataBusinessUnitId
                  "
                  :data-style-height="$omwConfig.trustpilot.dataStyleHeight"
                  :data-style-width="$omwConfig.trustpilot.dataStyleWidth"
                  :data-theme="$omwConfig.trustpilot.dataTheme"
                  :url="$omwConfig.trustpilot.url"
                />
              </span>

              <timeago :datetime="currentDate" :auto-update="10 * 1000" />
            </div>
          </div>
        </div>
      </span>
    </div>
    <div v-else>
      <appointment-rebook-confirmation style="height: 80vh" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';

import EngineerPanel from '@/components/EngineerPanel.vue';
import HereMap from '@/components/Heremap.vue';
import Timeago from '@/components/Timeago.vue';
import AppointmentDetails from '@/components/AppointmentDetails.vue';
import AppointmentRebookConfirmation from '@/components/appointmentBooking/AppointmentRebookConfirmation.vue';
import AdditionalInfo from '@/components/optional/AdditionalInfo.vue';

export default defineComponent({
  components: {
    AppointmentDetails,
    AppointmentRebookConfirmation,
    HereMap,
    EngineerPanel,
    Timeago,
    AdditionalInfo,
    Trustpilot: () =>
      import(
        /* webpackPrefetch: true */ '@/components/optional/Trustpilot.vue'
      ),
    Marketing: () =>
      import(/* webpackPrefetch: true */ '@/components/optional/Marketing.vue'),
  },
  data() {
    return {
      mapMounted: false,
      marketingCampaignFailed: false,
      engineerPanelHeight: undefined,
      logos: this.$omwConfig.logos,
      marketingReady: false,
      tooLateToRebookCancelStatuses: this.$omwConfig.reschedule
        .tooLateToRebookCancelStatuses || [
        'cancelled',
        'canceled',
        'completed',
        'enroute',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'currentDate',
      'rescheduleDetails',
      'activityDetails',
      'engineerDetails',
      'token',
      'apptInPast',
      'apptInFuture',
      'contactCentre',
    ]),
    showMap() {
      return this.activityDetails.latitude || this.engineerDetails.latitude;
    },
    resourceType() {
      return this?.engineerDetails?.type;
    },
    isPastCutoffTime() {
      const daysIntoFuture =
        this.$omwConfig?.reschedule?.rebookCutoff?.numberOfDaysInFuture;
      const cutoffTime = this.$omwConfig?.reschedule?.rebookCutoff?.cutoffTime;
      if (daysIntoFuture === undefined || cutoffTime === undefined)
        return false;
      const apptDate = DateTime.fromISO(this.activityDetails.date);
      const now = DateTime.now();
      const cutoffArray = cutoffTime.split(':');
      const cutoffHour = cutoffArray[0];
      const cutoffMins = cutoffArray[1];
      const cutoffDateTime = apptDate
        .minus({
          days: daysIntoFuture,
        })
        .startOf('day')
        .set({
          hours: cutoffHour,
          minutes: cutoffMins,
        });
      return now > cutoffDateTime;
    },
    rescheduleEnabled() {
      return (
        this.$omwConfig?.reschedule?.enabled && // Reschedule option is turned on
        !this.tooLateToRebookCancelStatuses.includes(
          this.activityDetails.status,
        ) && // Activity is in a status that can be rescheduled
        this.activityDetails.rebookable && // Activity type is rebookable
        !this.apptInPast && // Activity is not in the past
        this.allowUserRebook && // User is allowed to rebook e.g. not a contact centre agent if that type is excluded
        this.inRebookableBucket && // Is in a bucket that has rebooking turned on
        !this.isPastCutoffTime // If we're not allowed to rebook on the day, and appointment is today
      );
    },
    inRebookableBucket() {
      if (
        !this.$omwConfig?.reschedule?.rebookBuckets ||
        this.$omwConfig?.reschedule?.rebookBuckets.length === 0 // Rebooking allowed for all buckets if rebookBuckets in config is not specified or has no entries
      ) {
        return true;
      }
      if (
        this.$omwConfig?.reschedule?.rebookBuckets.includes(
          this.engineerDetails.parentId,
        ) // Rebooking allowed if the rebookBuckets property in config has this engineer's parent bucket specified
      ) {
        return true;
      }
      return false;
    },
    allowUserRebook() {
      // We want to default to true if not specified
      const contactCentreRebook =
        typeof this.$omwConfig?.reschedule?.allowContactCentreRebook !==
        'undefined'
          ? this.$omwConfig?.reschedule?.allowContactCentreRebook
          : true;
      if (contactCentreRebook) return true; // If contact centre user can rebook, this computed variable should always return true

      // Contact centre users aren't allowed to rebook so check if this is coming from a contact centre request
      return !this.contactCentre;
    },
    photoSource() {
      const engineerKeys = Object.keys(this.engineerDetails);
      const defaultPhoto = `img/${this.$omwConfig.display.defaultEngineerPhoto}`;

      if (this.apptInFuture && this.$omwConfig.display.defaultEngineerPhoto) {
        return defaultPhoto;
      } else if (this.apptInFuture) {
        return undefined;
      }
      if (
        !engineerKeys.includes('photo') ||
        this.engineerDetails['photo'].match(/No photo available/gi)
      ) {
        if (this.$omwConfig.display.defaultEngineerPhoto) {
          return defaultPhoto;
        } else {
          return undefined;
        }
      }

      return this.engineerDetails['photo'];
    },
    marketingEnabled() {
      return this.$omwConfig?.marketing?.enabled;
    },
    trustpilotEnabled() {
      return this.$omwConfig?.trustpilot?.enabled;
    },
    trustpilotPadding() {
      let paddingTop = '10em';
      return {
        paddingBottom: '1em',
        paddingTop,
      };
    },
    contactUsEnabled() {
      return !this.$omwConfig.display.disableContactUs;
    },
    relativeToMarketing() {
      return {
        height: this.marketingHeight,
        width: '30em',
      };
    },
    marketingHeight() {
      return 10;
    },
    mapHeight() {
      if (this.isMobile) {
        return this.$omwConfig.here.mapHeightMobile;
      }
      return this.$omwConfig.here.mapHeightDesktop;
    },
    mapWidth() {
      if (this.isMobile) {
        return this.$omwConfig.here.mapWidthMobile;
      }
      return this.$omwConfig.here.mapWidthDesktop;
    },
    disclaimerStyle() {
      return {
        fontStyle: 'italic',
        color: 'black',
      };
    },
  },
  updated() {
    this.engineerPanelHeight = this.$refs?.engineerPanel?.$el?.offsetHeight;
  },
  methods: {
    nowShowMarketing(evt) {
      if (evt) {
        this.marketingReady = true;
      }
    },
    marketingFailed() {
      this.marketingCampaignFailed = true;
    },
    showReschedule() {
      this.$router.push({
        name: 'Rebook',
        query: {
          token: this.token,
        },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';
$margin: 0.25rem;

.content-desktop {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: flex;
}
.content-mobile {
  display: flex;
}
@media screen and (max-width: 768px) {
  .content-desktop {
    display: none;
  }
  .content-mobile {
    display: block;
  }
}

.engineer-marketing-flex-container {
  display: flex;
  flex-flow: wrap row-reverse;
  margin: 0 -$margin;
  text-align: center;
}

.margin-wrap {
  flex: 1 1 auto;
  padding: 1rem;
  border: $margin;
}

.faint-box {
  @if variable-exists(additional-info-panel-border) {
    border-style: solid;
    border-width: 1px;
    border-color: $additional-info-panel-border;
    border-radius: 4px;
  }
}

span.avoidwrap {
  display: inline-block;
}
</style>
