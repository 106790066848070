import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Buefy from 'buefy';
import IntercomVuePlugin from '@mathieustan/vue-intercom';

import App from './App.vue';
import i18n from './lang/lang';
import store from '@/store';
import router from '@/router';
import globalUtilities from '@/mixins/globalUtilities.vue';
import config from '@/config/config';
import heremaps from './heremaps';
import './fontawesome.js';

if (config.intercom.enabled) {
  Vue.use(IntercomVuePlugin, {
    appId: config.intercom.appId,
    hide_default_launcher: false,
  });
}

Vue.use(heremaps);

Vue.prototype.$omwConfig = config;

// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('vue-fontawesome', FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});

import { extend, setInteractionMode } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';

setInteractionMode('eager');
extend('email', {
  ...email,
  message: 'Please provide a valid email so we can contact you',
});

Vue.mixin(globalUtilities);

Vue.config.productionTip = false;

export const app = new Vue({
  render: (h) => h(App),
  store,
  router,
  i18n,
}).$mount('#app');
