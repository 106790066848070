import { DateTime } from 'luxon';
import configData from '@/config/config';

const TIME_FORMAT = configData.display.timeFormat || 'HH:mm';
const OFS_DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export function calculateEtaBuffer(etaDateTime) {
  let bufferDateBase = DateTime.fromISO(etaDateTime);
  if (!bufferDateBase.isValid) {
    bufferDateBase = DateTime.fromFormat(etaDateTime, OFS_DATE_FORMAT);
  }

  const bufferedStartDate = bufferDateBase
    .minus({
      minutes: configData.display.eta.bufferBeforeMins,
    })
    .toFormat(TIME_FORMAT);

  const bufferedEndDate = bufferDateBase
    .plus({
      minutes: configData.display.eta.bufferAfterMins,
    })
    .toFormat(TIME_FORMAT);
  return {
    bufferedStartDate,
    bufferedEndDate,
  };
}
