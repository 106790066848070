import configData from '@/config/config';
import axios from 'axios';

const apiClient = axios.create({
  baseURL: `${configData?.reschedule?.url}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export async function getAppointmentData(
  token,
  dateFrom,
  numberOfDays,
  timezone,
) {
  const params = new URLSearchParams({
    numberOfDays,
    dateFrom,
    timezone,
    expiryMins: configData.reschedule.expiryMins,
  });

  const { data } = await apiClient.get(`/rebook/${token}/slots`, {
    params,
  });
  return data;
}

export async function cancelAppointment(token) {
  await apiClient.delete(`/cancel/${token}`);
}

export async function acceptSlot({
  token,
  slotId,
  apptRequestId,
  startTime,
  endTime,
}) {
  const data = {
    slotId,
    startTime,
    endTime,
  };
  try {
    await apiClient.post(`/rebook/${token}/accept/${apptRequestId}`, data);
    return true;
  } catch (err) {
    if (err?.response?.status === 410) {
      // Slot has been taken by another customer
      return false;
    }
    throw err;
  }
}
